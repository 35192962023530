(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.constant:navigationBar
   *
   * @description
   *
   */
  angular
    .module('neo')
    .value('neoNavigationBarItems', [
    // {
    //  text: 'NAV_TOWN_HALL',
    //  slink: 'home.ajuntaments',
   //    selected: false,
   //   permission: {
     //    name: 'TOWN_HALL',
      //   acl: 'R'
   //    }
   //  },
     //{
    //   text: 'NAV_ENTITIES',
     //  selected: false,
     //  slink: 'home.entitats',
     //  permission: {
      //   name: 'ENTITY',
      //   acl: 'R'
     //  }
    // },
      {
        text: 'NAV_COMPETITION',
        selected: false,
        children: [
       //  {
      //     text: 'NAV_SEASONS',
       //    slink: 'home.seasons',
       //    permission: {
       //      name: 'SEASON',
       //     acl: 'R'
       //    }
       // },
       // {
       //     text: 'PHASES.NAV_PHASES',
       //    slink: 'home.phases',
       //    permission: {
       //     name: 'LOCAL_PHASE',
         //   acl: 'R'
        //  }
      //  },
       // {
        //  text: 'PHASES.INSCRIPTION',
          // slink: 'home.phases.inscription',
       //    permission: {
       //      name: 'LOCAL_PHASE',
       //      acl: 'R'
        //   }
        //  },
        //  {
        //    text: 'CHAMPIONSHIPS.NAV',
        //    slink: 'home.championships',
        //    permission: {
        //      name: 'LOCAL_PHASE',
         //     acl: 'R'
         //   }
       //   },
        //  {
         //   text: 'FACILITIES.NAV',
        //    slink: 'home.facilities',
        //    permission: {
        //      name: 'FACILITY',
        //      acl: 'R'
        //    }
       //   },
         
                 {
                         text: 'Control de votacions per tècnics',
                         slink: 'home.jvp',
                         permission: {
                          name: 'FACILITY',
                              acl: 'R'
                             }
                     },
             //        {text: 'Introduïr resultats JVP',
               //          slink: 'home.jvp2',
              //           permission: {
                //          name: 'FACILITY',
                //              acl: 'R'
                  //           }
                 //        },
                     {text: 'Introduïr resultats JVP',
                         slink: 'home.comite',
                         permission: {
                          name: 'FACILITY',
                              acl: 'R'
                             }
                         }
                        
         ]},
   //   {
     //   text: 'NAV_TEAMS',
       // selected: false,
    //    children: [
      //    {
       //     text: 'NAV_TEAMS_LIST',
         //   slink: 'home.teams',
      //      permission: {
       //       name: 'TEAM',
     //         acl: 'R'
       //     }
     //     },
       //   {
     //       text: 'PEOPLE.LIST',
       ///     slink: 'home.persons'
       //   }
     //   ]
    //  },
     // {
    //    text: 'MATCHES.NAV',
    //    selected: false,
    //    children: [
      //    {
        //    text: 'MATCHES.NAV',
     //       slink: 'home.matches'
       //   },
      //    {
        //    text: 'POSTPONEMENTS.NAV',
      //      slink: 'home.postponements',
        //    permission: {
      //        name: 'POSTPONEMENT',
        //      acl: 'C'
        //    }
      //    },
     //     {
     //       text: 'POSTPONEMENTS.POSTPONED_NAV',
    //        slink: 'home.postponements.postponedMatches',
      //      permission: {
    //          name: 'POSTPONEMENT',
      //        acl: 'R'
     //       }
       //   },
      //    {
        ///    text: 'CANCELLATIONS.NAV',
       ///     slink: 'home.cancellations',
  //          permission: {
    //          name: 'CANCELLATION',
      //        acl: 'R'
 //           }
   //       },
     //     {
       //     text: 'CANCELLATIONS.CANCELLED_NAV',
         //   slink: 'home.cancellations.canceledMatches',
           // permission: {
 //             name: 'CANCELLATION',
   //           acl: 'R'
     //       }
       //   }
   //     ]
  //    },
      {
        text: 'REFEREES.NAV_REFEREES',
        slink: 'home.referees',
        selected: false,
        permission: {
          name: 'REFEREES',
          acl: 'R'
        }
      },{
        text: 'NAV_TUTOR_GRADA',
        slink: 'home.tutorGrada',
        selected: false,
        permission: {
          name: 'REFEREES',
          acl: 'R'
        }
      }//,
    //  {
    //    text: 'SEARCH',
    //    selected: false,
    //    children: [
    //      {
    //        text: 'LICENSES.SEARCH',
     //       slink: 'home.licenseSearch',
    //        permission: {
    //          name: 'SEARCH_PEOPLE',
  //            acl: 'R'
    //        }
   //       },
    //      {
    //        text: 'STATISTICS.LICENSES',
       //     slink: 'home.statistics.licenses',
       //     permission: {
   //           name: 'STATISTICS',
   //           acl: 'R'
    //        }
  //        },
    //      {
    //        text: 'STATISTICS.TEAMS',
   //         slink: 'home.statistics.teams',
    //        permission: {
   //           name: 'STATISTICS',
   //           acl: 'R'
   //         }
   //       },
    //      {
    //        text: 'STATISTICS.ENTITIES',
   //         slink: 'home.statistics.entities',
   //         permission: {
   //           name: 'STATISTICS',
   //           acl: 'R'
       //     }
     //     }
  //      ]
    //  },
    //  {
    //    text: 'DOCUMENTATION.NAV',
    ///    selected: false,
    //    children: [
   //       {
   //         text: 'DOCUMENTATION.COUNCIL',
    //        url: 'http://cebllob.org/documents/consell'
   //       },
   //       {
   //         text: 'DOCUMENTATION.TOWN_HALLS',
  //          url: 'http://cebllob.org/documents/ajuntaments'
   //       },
    //      {
    //        text: 'DOCUMENTATION.ENTITIES',
    //        url: 'http://cebllob.org/documents/entitats'
    //      }
   //     ]
  //    },{
  //          text: 'NEO INDI',
   //         url: 'http://neoprograma.cebllob.com/neoindi',
    //    permission: {
    //      name: 'ENTITY',
   //       acl: 'R'
  //      }
   //       }
    ]);
}());